//@ts-nocheck

/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, ChangeEvent, DragEvent, MouseEvent } from 'react';
import { Outlet } from 'react-router-dom';
import { OrderItem, FolderManagement, S3FolderUpload, ShareItem } from '../../components/S3/S3_Components.js';
import { CLOUDFRONT_URL, deleteFile, uploadFiles } from '../../lib/S3.js';
import toast from 'react-hot-toast';
import Loader from '../../components/Loader.js';
import { FaRegShareSquare } from 'react-icons/fa';
import { AiFillCopy } from 'react-icons/ai';

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'avif', 'img', 'svg+xml'];

// Define the types for the props of S3FileUpload
interface S3FileUploadProps {
    selectedFiles: Array<FileItem> | null;
    folderName: string;
    setSelectedFiles: (files: Array<FileItem> | null) => void;
    setFileList: (fileList: any[]) => void;
}

// Define the type for FileItem (with preview and file properties)
interface FileItem {
    file: File;
    preview: string;
}

const S3Upload: React.FC = () => {
    return (
        <div className="w-full px-4 py-3 min-h-screen max-w-screen-2xl mx-auto">
            <Outlet />
            <S3FolderUpload />
            <FolderManagement />
        </div>
    );
};

const S3FileUpload: React.FC<S3FileUploadProps> = ({ selectedFiles, folderName, setSelectedFiles, setFileList }) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>) => {
        const files = event.type === 'drop' ? (event as DragEvent<HTMLDivElement>).dataTransfer.files : (event as ChangeEvent<HTMLInputElement>).target.files;

        let selectedFilesArray: FileItem[] = [];

        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onloadend = () => {
                    selectedFilesArray.push({
                        file,
                        preview: reader.result as string,
                    });

                    if (selectedFilesArray.length === files.length) {
                        setSelectedFiles(selectedFilesArray);
                    }
                };

                reader.readAsDataURL(file);
            }
        }
    };

    const handleUpload = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (selectedFiles) {
            try {
                const fileUrl = await uploadFiles(selectedFiles, folderName, setIsLoading, setSelectedFiles, setFileList);
                console.log(`File uploaded successfully. URL: ${fileUrl}`);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    return (
        <div className="flex flex-col justify-center items-center gap-3 w-full bg-slate-300 rounded-lg md:p-5 p-3 dark:bg-gray-800">
            <h2 className="md:text-xl underline text-sm font-medium dark:text-slate-200">File Upload</h2>
            <FileInput handleFileChange={handleFileChange} index="file-upload" multiple={true} />
            <button className={`btn btn-primary w-full min-w-full`} onClick={handleUpload}>
                {isLoading ? 'Uploading...' : 'Upload'}
            </button>
            <div className="overflow-y-auto w-full">
                {selectedFiles && selectedFiles.length > 0 && (
                    <div className="mt-5 grid-cols-2 md:grid-cols-3 grid p-2 gap-2 bg-rose-200 dark:bg-rose-900 rounded-lg">
                        <h2 className="text-2xl col-span-full dark:text-white underline text-center mb-2">Selected Media</h2>
                        {selectedFiles.map((fileItem, i) => {
                            let fileElement: React.ReactNode = null;

                            if (fileItem.file.type.includes('image')) {
                                fileElement = <OrderItem key={i}  url={fileItem.preview} />;
                            } else if (fileItem.file.type.includes('video')) {
                                fileElement = <video src={fileItem.preview} controls className="h-56 w-full" />;
                            } else if (fileItem.file.type.includes('application')) {
                                fileElement = <iframe src={fileItem.preview} title={`PDF ${i}`} width="100%" height="250px" />;
                            } else {
                                fileElement = (
                                    <a href={fileItem.preview} target="_blank" rel="noopener noreferrer">
                                        Download File
                                    </a>
                                );
                            }

                            return (
                                <div key={i} className="w-full p-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-hidden relative">
                                    {fileElement}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

// Define the props for FileInput
interface FileInputProps {
    multiple?: boolean;
    accept?: string;
    handleFileChange: (event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>) => void;
    index: string;
    uploadToFolder?: string;
}

const FileInput: React.FC<FileInputProps> = ({ multiple, accept, handleFileChange, index, uploadToFolder }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
        handleFileChange(e);
    };

    return (
        <div className={`flex items-center justify-center w-full`}>
            <label
                onDragEnter={(e: React.DragEvent<HTMLLabelElement>) => handleDragEnter(e as unknown as DragEvent<HTMLDivElement>)}
                onDragLeave={(e: React.DragEvent<HTMLLabelElement>) => handleDragLeave(e as unknown as DragEvent<HTMLDivElement>)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e: React.DragEvent<HTMLLabelElement>) => handleDrop(e as unknown as DragEvent<HTMLDivElement>)}
                htmlFor={index}
                className={`flex flex-col items-center justify-center w-full h-max border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-900 dark:hover:bg-gray-950 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 p-2 ${
                    isDragging ? 'dark:border-blue-500 border-blue-300' : 'border-gray-300'
                }`}
            >
                <div className="flex flex-col items-center justify-center">
                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5A5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                        {accept ? accept : 'SVG, PNG, JPG, or GIF'}
                        {multiple ? ' (Multiple files allowed)' : ' (Single file only)'}
                    </p>
                    {uploadToFolder && <p className="text-xs text-gray-500 dark:text-gray-400">{`Uploaded to folder: ${uploadToFolder}`}</p>}
                </div>
            </label>
            <input id={index} type="file" className="hidden" name="" multiple={multiple} accept={accept ? accept : 'image/*,video/*'} onChange={handleFileChange} />
        </div>
    );
};

// Additional component conversions...

interface S3FileListProps {
  fileList: { Key: string }[]; // Adjust type based on your file object structure
  isFetching: boolean;
  folderName: string;
  setIsFetching: (isFetching: boolean) => void;
  setFileList: (fileList: { Key: string }[]) => void; // Adjust type based on your file object structure
  getFoldersObjects: (folderName: string) => void;
}


export const S3FileList: React.FC<S3FileListProps> = ({
  fileList,
  isFetching,
  folderName,
  setIsFetching,
  setFileList,
  getFoldersObjects,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);
  // const { showNotification } = useNotificationContext();

  const handleConfirmDelete = async (fileKey: string) => {
    try {
      await deleteFile(fileKey);
      getFoldersObjects(folderName);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.preventDefault();

    navigator.clipboard.writeText(url).then(() => {
      toast.success('Copied Successfully!');

    });
  };

  return (
    <div className="py-5 w-full bg-slate-100 dark:bg-gray-700 rounded-xl mt-4 p-2">
      {isFetching ? (
        <div className="w-full min-h-[50vh] flex items-center justify-center col-span-full row-span-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="col-span-full w-full">
            <S3FileUpload
              selectedFiles={selectedFiles }
              folderName={folderName}
              setSelectedFiles={setSelectedFiles}
              fileList={fileList}
              setFileList={setFileList}
            />
          </div>

          {fileList.length === 0 ? (
            <div className="w-full h-auto overflow-hidden object-cover flex items-center justify-center flex-col mt-10">
              <img
                src="https://dzo5ib7e70yg4.cloudfront.net/gaurav2/empty-folder1.jpeg"
                alt="Empty Folder"
                className="w-full h-full max-w-[200px] rounded-xl"
                loading="lazy"
              />
              <h2 className="text-3xl md:text-6xl dark:text-white font-semibold">
                Empty Folder
              </h2>
            </div>
          ) : (
            <div className="py-5 grid grid-cols-1 bg-slate-100 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 md:gap-8 gap-5 dark:bg-gray-700 rounded-xl mt-4 p-2 place-content-center place-items-center">
              {fileList.map((object, i) => {
                const fileExtension = object.Key.includes(".")
                  ? object.Key.split(".").pop()
                  : "img"; // Default to "img"

                const encodedKey = object.Key.replace(/ /g, "+");
                const url = `${CLOUDFRONT_URL}/${encodedKey}`;
                let fileElement: JSX.Element | null = null;

                if (imageExtensions.includes(fileExtension?.toLowerCase() || '')) {
                  fileElement = (
                    <OrderItem key={i}  url={url} />
                  );
                } else if (fileExtension === "mp4") {
                  fileElement = (
                    <video src={url} controls className="h-56 w-full" />
                  );
                } else if (fileExtension === "pdf") {
                  fileElement = (
                    <iframe
                      src={url}
                      title={`PDF ${i}`}
                      width="100%"
                      height="250px"
                    />
                  );
                } else {
                  fileElement = (
                    <a
                      href={url}
                      target="_blank"
                      className="h-56"
                      rel="noopener noreferrer"
                    >
                      Download File
                    </a>
                  );
                }

                return (
                  <div
                    key={i}
                    className="w-full p-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-hidden relative"
                  >
                    {fileElement}

                    <div className="w-full flex justify-start items-center relative">
                      <a
                        className={`btn btn-secondary mt-3`}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="px-5 py-2 w-full transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 flex items-center justify-center gap-1">
                          <FaRegShareSquare />
                          Open
                        </span>
                      </a>

                      <button
                        onClick={(e) => handleCopy(e, url)}
                        className={`btn btn-primary mt-3`}
                      >
                        <span className="px-5 py-2 w-full transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 flex items-center justify-center gap-1">
                          <AiFillCopy />
                          Copy URL
                        </span>
                      </button>

                      <ShareItem
                        url={url}
                        fileKey={object.Key}
                        handleDelete={handleConfirmDelete}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};




export default S3Upload;
