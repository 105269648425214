import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { cn } from '../lib/utils';
import BackButton from './BackButton';

// const normalBreadCrumbClassName = `p-1.5 px-3 ltr:pl-6 rtl:pr-6 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70`;

// const activeBreadCrumbClassName = `bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]`;

const BreadCrumb = () => {
    const breadcrumbs = useReactRouterBreadcrumbs();

    // console.log(breadcrumbs);

    return (
        <div className="pb-5">
            <ul className="flex space-x-2 rtl:space-x-reverse flex-wrap gap-2">
                {/* {breadcrumbs.map(({ breadcrumb }, index) => (
                    <li key={index} className={cn(`${index !== 0 && "before:content-['/']"} ltr:before:mr-2 rtl:before:ml-2`)}>
                        <Link to={`/${breadcrumb}`} className={cn(` hover:underline`)}>
                            {breadcrumb}
                        </Link>
                    </li>
                ))} */}
                {breadcrumbs.map(({ match, breadcrumb }, index) => (
                    <li key={index} className={cn(`${index !== 0 && "before:content-['/']"} ltr:before:mr-2 rtl:before:ml-2`)}>
                        <NavLink to={match.pathname} className={cn(`${index === breadcrumbs?.length - 1 && 'text-primary'} hover:underline`)}>
                            {breadcrumb}
                        </NavLink>
                    </li>
                ))}

                <BackButton />
            </ul>
        </div>

        // <ol className="flex text-primary font-semibold dark:text-white-dark">
        //     <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
        //         <button type="button" className={normalBreadCrumbClassName}>
        //             Home
        //         </button>
        //     </li>
        //     <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
        //         <button type="button" className={activeBreadCrumbClassName}>
        //             Components
        //         </button>
        //     </li>
        //     <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
        //         <button type="button" className={normalBreadCrumbClassName}>
        //             UI Kit
        //         </button>
        //     </li>

        //     <BackButton />
        // </ol>
    );
};

export default BreadCrumb;
