import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react'
import axiosInstance from '../../api/axiosInstance';
import { Box, Button, Checkbox, Flex, Modal, ModalBase, Radio, Text, TextInput, } from '@mantine/core';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { IconPlus } from '@tabler/icons-react';
import SideViewLayout from '../../components/Layouts/SideViewLayout';

import toast from 'react-hot-toast';
import useSideView from '../../hooks/useSideView';
import useFormData from '../../hooks/useFormData';
import EditControllerButton from '../../components/EditControllerButton';




const fetchInvalidDomains = async () => {
    try {
        const { data } = await axiosInstance.get("/invalid_domains");
        return data
    } catch (error) {
        console.log(error)
    }
}

type Domains = {
    domains: string;
    id: string;
}


const initialFormData = {
    domains: '',
    id: '',
}

const useDomains = () => {
    const [domains, setDomains] = useState<Domains[]>([]);
    const {
        formData,
        handleFormDataChange,
        resetFormData
    } = useFormData(initialFormData)

    const handleAddOrEditDomains = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axiosInstance.post('/invalid_domains/add_edit', formData);
            console.log(data)
            refetch()
            toast.success('Data Domain Added Successfully');
            toggleSideView()
        } catch (error) {
            console.log(error)
            toast.error('Error in Adding Domain');
        }
    }

    const handleDeleteDomain = async (id) => {
        try {
            const { data } = await axiosInstance.post('/invalid_domains/delete', { id });
            refetch()
            toast.success('Data Domain Deleted Successfully');
        } catch (error) {
            console.log(error)
            toast.error('Error in Deleting Domain');
        }
    }

    const { isSideViewOpen, toggleSideView } = useSideView()

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['all-Domains'],
        queryFn: () => fetchInvalidDomains(),
    });

    useEffect(() => {
        setDomains(data)
    }, [data])

    return {
        domains,
        isLoading,
        handleAddOrEditDomains,
        handleDeleteDomain,
        toggleSideView,
        isSideViewOpen,
        formData,
        handleFormDataChange,
        resetFormData
    };
}

const Domains = () => {

    const {
        domains,
        isLoading,
        handleAddOrEditDomains,
        handleDeleteDomain,
        toggleSideView,
        isSideViewOpen,
        formData,
        handleFormDataChange,
        resetFormData
    } = useDomains();

    const columns = useMemo<MRT_ColumnDef<Domains>[]>(
        () => [
            {
                id: 'Domains',
                header: '',
                columns: [
                    { accessorKey: 'id', header: 'Id', size: 50 },
                    { accessorKey: 'domains', header: 'Domain Name', size: 50 },
                    {
                        accessorKey: 'edit',
                        header: "Edit",
                        Cell: ({ row }) => {
                            return <EditControllerButton onClick={() => {
                                handleFormDataChange('domains', row.original.domains)
                                toggleSideView()
                            }} className=''>Edit</EditControllerButton>
                        }
                    },
                    {
                        accessorKey: 'delete',
                        header: "Delete",
                        Cell: ({ row }) => {
                            return <EditControllerButton onClick={() => handleDeleteDomain(row.original.id)} className='bg-red-500 hover:bg-red-600'>Delete</EditControllerButton>
                        }
                    }
                ],
            },
        ],
        [],
    );
    const table = useMantineReactTable({
        columns,
        data: domains ?? [],
        state: {
            isLoading: isLoading,
        },
        enablePagination: true,
        renderTopToolbar: () => {
            return (
                <Flex p="xs" justify="space-between">
                    <Text className='uppercase font-bold text-xl'>Domains</Text>
                    <EditControllerButton onClick={() => { toggleSideView(); resetFormData() }}> <IconPlus /> Add New Domain</EditControllerButton>
                </Flex>
            );
        },
    });
    return (
        <>
            <SideViewLayout isOpen={isSideViewOpen} onClose={toggleSideView} title={formData.domains ? "Edit Domain" : "Add Domain"}>
                <form className='flex flex-col gap-2' onSubmit={handleAddOrEditDomains}>
                    <TextInput value={formData.domains} placeholder='Domain' onChange={(e) => handleFormDataChange('domains', e.target.value)} />

                    <EditControllerButton type='submit'>Submit</EditControllerButton>
                </form>
            </SideViewLayout>
            <MantineReactTable table={table} />
        </>
    )
}

export default Domains