import { Button } from '@mantine/core';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

type Props = {
    children: any;
    className?: any;
    type?: 'button' | 'submit',
    onClick?: any;
    loading?: boolean;
    disabled?: boolean;
}

const EditControllerButton = ({ children, disabled = false, className, onClick, type, loading }: Props) => {
    const { pathname } = useLocation()
    let path = '/' + pathname.split('/')[1]
    const { user, edit_permission, } = useSelector((state: any) => state?.user)
    let check_permission = false;

    if (user.role == 'admin')
        check_permission = true;
    else
        check_permission = edit_permission[path] ?? false
    return (
        <Button disabled={!check_permission || disabled} loading={loading} className={className} type={type ?? "button"} onClick={onClick}>
            {children}
        </Button>
    )
}

export default EditControllerButton