import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { BUCKET_NAME, CLOUDFRONT_URL, getFileUrl, listS3Objects, uploadFiles, deleteObjectFromS3, createFolderInS3, renameObjectInS3 } from '../../lib/s3Service';
import ConfirmationModal from '../../components/ConfirmationModal';
import toast from 'react-hot-toast';
import Loader from '../../components/Loader';
import { cn } from '../../lib/utils';
import axios from 'axios';
// import { compressAndUploadImage } from '../../lib/compress';

type S3Object = {
    Key: string;
};

type S3Folder = {
    Prefix: string;
};

const S3Explorer: React.FC<{ bucketName?: string; cloudFrontUrl?: string }> = ({ bucketName = BUCKET_NAME, cloudFrontUrl = CLOUDFRONT_URL }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const prefix = searchParams.get('prefix') || ''; // Get prefix from query parameters
    const [objects, setObjects] = useState<S3Object[]>([]);
    const [folders, setFolders] = useState<S3Folder[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [actionLoading, setActionLoading] = useState<boolean>(false); // Separate state for actions like upload/delete
    const [isGridView, setIsGridView] = useState<boolean>(false); // State to toggle between views
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isCompressModalOpen, setIsCompressModalOpen] = useState<boolean>(false);
    const [selectedObject, setSelectedObject] = useState<string | null>(null); // Store the key of the file/folder to delete
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null); // State to manage selected files for upload
    const [selectedItems, setSelectedItems] = useState<string[]>([]); // State for multiple deletions
    const [newFolderName, setNewFolderName] = useState<string>(''); // State to manage new folder name
    const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false); // Modal for renaming
    const [newFileName, setNewFileName] = useState<string>(''); // New name for the file
    const [objectToRename, setObjectToRename] = useState<string | null>(null); // Store the file key to rename

    useEffect(() => {
        const fetchObjects = async () => {
            setLoading(true);
            setError(null);
            const adjustedPrefix = prefix === '' ? '' : prefix.endsWith('/') ? prefix : `${prefix}/`;
            try {
                const data = await listS3Objects(bucketName, adjustedPrefix);
                if (data) {
                    setObjects(data.Contents || []);
                    setFolders(data.CommonPrefixes || []);
                }
            } catch (err) {
                setError('Failed to fetch data from S3');
            }
            setLoading(false);
        };

        fetchObjects();
    }, [bucketName, prefix]);

    const handleFolderClick = (prefix: string) => {
        setSearchParams({ prefix: prefix });
    };

    const handleFileClick = async (fileName: string) => {
        await getFileUrl(fileName, '', 'download');
    };

    const handleCopyUrl = (fileName: string) => {
        const fileUrl = `${cloudFrontUrl}/${fileName}`;
        navigator.clipboard
            .writeText(fileUrl)
            .then(() => {
                toast.success('URL copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleBreadcrumbClick = (index: number) => {
        const pathSegments = prefix.split('/').filter(Boolean);
        const newPrefix = pathSegments.slice(0, index + 1).join('/');
        setSearchParams({ prefix: newPrefix });
    };

    const Breadcrumbs = () => {
        const pathSegments = prefix.split('/').filter(Boolean);
        return (
            <div className="flex items-center space-x-2 ">
                <span className="cursor-pointer text-blue-500 hover:underline" onClick={() => setSearchParams({ prefix: '' })}>
                    Root
                </span>
                {pathSegments.map((segment, index) => (
                    <span key={index} className="cursor-pointer text-blue-500 hover:underline" onClick={() => handleBreadcrumbClick(index)}>
                        {' > '} {segment}
                    </span>
                ))}
            </div>
        );
    };

    const toggleView = () => {
        setIsGridView((prev) => !prev);
    };

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'avif', 'img', 'svg+xml', ''];

    const renderFileContent = (fileName: string) => {
        const extension = fileName.split('.').pop()?.toLowerCase();
        if (isGridView && imageExtensions.includes(extension || '')) {
            return <img src={`${cloudFrontUrl}/${fileName}`} alt={fileName} className="w-full h-auto rounded shadow-sm p-2" />;
        } else if (extension === 'pdf') {
            return <iframe src={`${cloudFrontUrl}/${fileName}`} title={fileName} className="w-full h-48 border border-gray-300 rounded"></iframe>;
        } else {
            return <span className="text-gray-500">{fileName}</span>;
        }
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            setSelectedFiles(files);
        }
    };

    const handleUploadSubmit = async () => {
        if (selectedFiles) {
            setActionLoading(true); // Show loading text during upload
            const toastId = toast.loading(`Uploading...`);
            const fileArray = Array.from(selectedFiles);
            try {
                const uploadedFiles = await uploadFiles(fileArray, prefix);

                // Update the objects state with the new uploaded files
                const newObjects = uploadedFiles.map((file: any) => ({
                    Key: `${prefix}${file.fileName}`, // Assuming file.name contains the file name
                }));

                setObjects((prevObjects) => [...newObjects, ...prevObjects]); // Append new objects to existing ones

                toast.success('Files uploaded successfully!');
                setSelectedFiles(null);
            } catch (err) {
                console.error('File upload failed:', err);
                toast.error('Failed to upload files.');
            } finally {
                toast.remove(toastId);
                setActionLoading(false); // Hide loading text after upload
            }
        }
    };

    // console.log(objects);
    // S3Explorer.tsx

    // const handleDeleteObject = async (key: string) => {
    //     try {
    //         // Check if it's a folder by checking if it ends with a '/'
    //         if (key.endsWith('/')) {
    //             await deleteObjectFromS3(key); // Recursively delete all objects in folder
    //             toast.success('Folder deleted successfully!');
    //             setFolders((prevFolders) => prevFolders.filter((folder) => folder.Prefix !== key));
    //         } else {
    //             await deleteObjectFromS3(key); // Delete file
    //             toast.success('File deleted successfully!');
    //             setObjects((prevObjects) => prevObjects.filter((obj) => obj.Key !== key));
    //         }
    //         setIsDeleteModalOpen(false);
    //     } catch (err) {
    //         console.error('Deletion failed:', err);
    //         toast.error('Failed to delete object.');
    //     }
    // };

    const handleDeleteMultiple = async () => {
        setActionLoading(true); // Show loading text during multiple deletions
        const toastId = toast.loading(`Deleting...`);
        try {
            for (const key of selectedItems) {
                await deleteObjectFromS3(key);
            }
            toast.success('Selected items deleted successfully!');
            setSelectedItems([]);
            setObjects((prevObjects) => prevObjects.filter((obj) => !selectedItems.includes(obj.Key)));
        } catch (err) {
            console.error('Deletion failed:', err);
            toast.error('Failed to delete selected items.');
        } finally {
            toast.remove(toastId);
        }
        setActionLoading(false); // Hide loading text after deletions
    };

    const handleDeleteClick = () => {
        // setSelectedObject(key);
        setIsDeleteModalOpen(true);
    };
    const handleCompressClick = () => {
        // setSelectedObject(key);
        setIsCompressModalOpen(true);
    };

    // const handleConfirmDelete = () => {
    //     if (selectedObject) {
    //         handleDeleteObject(selectedObject);
    //     }
    // };

    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
        setSelectedObject(null);
    };
    const handleCancelCompress = () => {
        setIsCompressModalOpen(false);
        setSelectedObject(null);
    };

    const handleCreateFolder = async () => {
        setActionLoading(true); // Show loading text during folder creation

        if (newFolderName.trim()) {
            const toastId = toast.loading(`Creating folder ${newFolderName.trim()}`);
            const folderKey = `${prefix}${newFolderName}/`;
            try {
                await createFolderInS3(folderKey);
                toast.success('Folder created successfully!');
                setFolders([...folders, { Prefix: folderKey }]);
                setNewFolderName('');
            } catch (err) {
                toast.error('Failed to create folder.');
            } finally {
                toast.remove(toastId);
            }
        }
        setActionLoading(false); // Hide loading text after folder creation
    };

    const handleSelectItem = (key: string) => {
        setSelectedItems((prev) => {
            if (prev.includes(key)) {
                return prev.filter((item) => item !== key);
            } else {
                return [...prev, key];
            }
        });
    };

    const handleRenameClick = (key: string) => {
        setObjectToRename(key); // Store the key of the object to rename
        setIsRenameModalOpen(true); // Open the rename modal
    };

    const handleRenameSubmit = async () => {
        const toastId = toast.loading('Renaming...');

        if (objectToRename && newFileName.trim()) {
            setActionLoading(true); // Show loading during renaming
            // Extract the extension from the original file name
            const originalExtension = objectToRename.includes('.') ? objectToRename.split('.').pop() : 'webp'; // Default to 'webp' if no original extension exists

            // Check if the new file name has an extension
            const newExtension = newFileName.includes('.') ? '' : `.${originalExtension}`;

            // If the user didn't provide an extension, append the original extension or 'webp'
            const finalFileName = `${newFileName}${newExtension}`;

            const newKey = prefix + finalFileName; // Create new key with the new file name

            try {
                await renameObjectInS3(objectToRename, newKey); // Copy the object to the new key and delete the old one
                toast.success('File renamed successfully!');

                // Update object list
                setObjects((prevObjects) => prevObjects.map((obj) => (obj.Key === objectToRename ? { ...obj, Key: newKey } : obj)));
                setNewFileName('');
                setIsRenameModalOpen(false); // Close the modal
            } catch (err) {
                toast.error('Failed to rename the file.');
            } finally {
                setActionLoading(false); // Hide loading
                toast.remove(toastId);
            }
        }
    };

    const handleCompressImages = async () => {
        const toastId = toast.loading('compressing...');
        try {
            if (selectedItems.length > 0) {
                setActionLoading(true); // Show loading text during compression

                // const { data } = await axios.post(`http://localhost:5001/compress-multiple`, {
                //     fileKeys: selectedItems,
                // });
                const { data } = await axios.post(`${import.meta.env.VITE_API_URL}/compress-multiple`, {
                    fileKeys: selectedItems,
                });
                console.log(data);
                toast.success(data.message);
                setSelectedItems([]); // Clear the selected items after compression
                setActionLoading(false); // Hide loading text after compression
            }
        } catch (error) {
            toast.error('Something Went Wrong');
            console.log(error);
        } finally {
            setActionLoading(false); // Show loading text during compression
            toast.remove(toastId);
        }
    };

    return (
        <>
            <ConfirmationModal isOpen={isDeleteModalOpen} onConfirm={handleDeleteMultiple} onClose={handleCancelDelete} message={`Are you sure you want to delete ${selectedObject}?`} />
            <ConfirmationModal isOpen={isCompressModalOpen} onConfirm={handleCompressImages} onClose={handleCancelCompress} message={`Are you sure you want to Compress ${selectedObject}?`} />
            <ConfirmationModal isOpen={isRenameModalOpen} onConfirm={handleRenameSubmit} onClose={() => setIsRenameModalOpen(false)} message={`Rename ${objectToRename} to:`}>
                <input type="text" className="input w-full border  input-primary  p-2.5 rounded-md" value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />
            </ConfirmationModal>

            <div className="p-4">
                <div className="sticky top-16 bg-white dark:bg-slate-900 p-3 mb-3 z-10">
                    <div className="flex justify-between">
                        <h1 className="text-2xl font-bold ">S3 Explorer - Bucket: {bucketName}</h1>
                        <button className="btn-sm btn-primary" onClick={toggleView}>
                            Switch to {isGridView ? 'List' : 'Grid'} View
                        </button>
                    </div>

                    <Breadcrumbs />
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4 ">
                    {/* Create Folder Section */}
                    <div className=" flex items-center gap-2 shadow-sm">
                        <input
                            type="text"
                            placeholder="New Folder Name"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            // className="btn btn-primary"
                            className="input input-bordered input-primary w-full max-w-xs p-2.5 rounded-md"
                        />
                        <button className="btn btn-success" onClick={handleCreateFolder} disabled={!newFolderName.trim()}>
                            {/* Create Folder */}
                            {actionLoading ? 'Creating Folder...' : 'Create Folder'}
                        </button>
                    </div>

                    {/* Upload Files Section */}
                    <div className="flex items-center gap-2 shadow-sm">
                        <input type="file" multiple onChange={handleFileUpload} />
                        <button className="btn btn-success" onClick={handleUploadSubmit} disabled={!selectedFiles}>
                            {/* Upload Files */}
                            {actionLoading ? 'Uploading...' : 'Upload Files'}
                        </button>
                    </div>
                </div>

                {/* Multiple Deletion Section */}
                {selectedItems.length > 0 && (
                    <div className="mb-4 flex items-start justify-start gap-3">
                        <button className="btn btn-danger" onClick={handleDeleteClick}>
                            Delete Selected ({selectedItems.length}){/* {actionLoading ? 'Deleting...' : 'Delete Selected'} */}
                        </button>
                        <button className="btn btn-primary" onClick={handleCompressClick}>
                            Compress Selected({selectedItems.length})
                        </button>
                    </div>
                )}

                {loading ? (
                    <Loader />
                ) : error ? (
                    <div className="text-red-500">{error}</div>
                ) : (
                    <ul className={`space-y-2 ${isGridView ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4' : ''}`}>
                        {folders.map((folder) => (
                            <li
                                key={folder.Prefix}
                                className={`flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded transition duration-200 ${isGridView ? 'flex-col items-center' : ''}`}
                                onClick={() => handleFolderClick(folder.Prefix)}
                            >
                                <input
                                    type="checkbox"
                                    className="mr-2 form-checkbox"
                                    checked={selectedItems.includes(folder.Prefix)}
                                    onChange={() => handleSelectItem(folder.Prefix)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                <span role="img" aria-label="folder" className="mr-2">
                                    📁
                                </span>
                                {folder.Prefix.replace(prefix, '').replace('/', '')}
                                {/* <button
                                className="ml-4 px-2 py-1 text-xs text-white bg-red-500 hover:bg-red-600 rounded"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteClick(folder.Prefix);
                                }}
                            >
                                Delete
                            </button> */}
                            </li>
                        ))}
                        {objects.map((object) => (
                            <li
                                key={object.Key}
                                className={cn(
                                    `flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded transition duration-200 ${
                                        isGridView ? 'flex-col items-center border border-gray-200 dark:border-gray-700' : ''
                                    }`
                                )}
                                onClick={() => handleFileClick(object.Key)}
                            >
                                <input
                                    type="checkbox"
                                    className="mr-2 form-checkbox"
                                    checked={selectedItems.includes(object.Key)}
                                    onChange={() => handleSelectItem(object.Key)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                {isGridView ? (
                                    renderFileContent(object.Key)
                                ) : (
                                    <p className="flex items-center justify-start gap-1 w-full">
                                        <span role="img" aria-label="file" className="mr-2">
                                            📄
                                        </span>
                                        <span className="text-slate-400">{object.Key.replace(prefix, '')}</span>
                                    </p>
                                )}
                                <div className="flex items-center justify-between gap-4">
                                    <button
                                        className="btn-sm btn-success min-w-max"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopyUrl(object.Key);
                                        }}
                                    >
                                        Copy URL
                                    </button>
                                    {/* <button
                                    className="ml-4 px-2 py-1 text-xs text-white bg-red-500 hover:bg-red-600 rounded"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteClick(object.Key); // Trigger renaming process
                                    }}
                                >
                                    Delete
                                </button> */}
                                    <button
                                        className="btn-sm btn-info"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRenameClick(object.Key); // Trigger renaming process
                                        }}
                                    >
                                        Rename
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
};

export default S3Explorer;
