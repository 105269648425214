import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const getTimeAgo = (timestamp) => {
    const now = new Date();
    const givenTime = new Date(timestamp);
    //@ts-ignore
    const diff = (now - givenTime) / 1000; // Calculate difference in seconds

    if (diff < 0) {
        return formatDateFromTimestamp(timestamp);
    }

    if (diff < 60) {
        return `${Math.floor(diff)}s ago`;
    } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}m ago`;
    } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}h ago`;
    } else {
        return `${Math.floor(diff / 86400)}d ago`;
    }
};

export function formatDateFromTimestamp(timestamp) {
    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Create a formatted date string
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const getStatusColor = (status) => {
    switch (status) {
        case 'order_accepted':
            return 'success';
        case 'order_start_trip':
            return 'secondary';
        case 'order_end_job':
            return 'info';
        case 'order_pending':
            return 'warning';
        case 'order_cancel':
            return 'danger';
        // Add more cases for other status values as needed
        default:
            return 'info'; // Default color
    }
};

const formatDate = (date: any) => {
    if (date) {
        const dt = new Date(date);
        const month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1;
        const day = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
        return day + '/' + month + '/' + dt.getFullYear();
    }
    return '';
};

export const moneyFormatter = (number: number) => {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
    });

    const amount = Math.round(number);
    return formatter.format(amount);
};

export function getValueAfterDotCom(url) {
    if (url === '' || !url) {
        return 'Not Available';
    }
    const match = url?.match(/\.com\/(.*)/);

    if (match && match[1]) {
        return match[1];
    } else {
        // Return the original URL if ".com/" is not found
        return 'Link';
    }
}

export const getCurrentLocation = async () => {
    try {
        const position = await new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve(position);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
        // @ts-ignore
        const { latitude, longitude } = position.coords;
        const latlng = { lat: latitude, lng: longitude };
        console.log(latlng);
        return latlng;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const geocodeLatLng = (geocoder, latlng) => {
    return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
                resolve(results);
            } else {
                reject(new Error(`Geocode failed with status ${status}`));
            }
        });
    });
};

export const formatDateIntoText = (date) => {
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
};
