import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the user structure
interface User {
    // Define the structure of your user object
    permissions: [],
}

// Define the initial state
export interface UserState {
    user: User | null;
    permissions: any,
    edit_permission: {}
}

const initialState: UserState = {
    user: null,
    permissions: [],
    edit_permission: {}
};

// Create the user slice
const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User | null>) {
            console.log(action.payload?.permissions)
            state.user = action.payload;
            state.permissions = action.payload?.permissions.map((data: any) => {
                state.edit_permission[data?._resources?.resource_name] = data?.can_edit;
                return data?._resources?.resource_name;
            })

        },
        logout(state) {
            state.user = null;
            localStorage.removeItem("token")
        },

    },
});

// Export the action creators
export const { setUser, logout, } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
