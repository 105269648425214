import React from 'react'

const ErrorTestPage = () => {
    function handleNewFunction(): void {
        throw new Error('Function not implemented.')
    }

    return (
        <div>
            <button onClick={() => handleNewFunction()}>
                Error
            </button>
        </div>
    )
}

export default ErrorTestPage