import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { setUser } from '../store/userSlice';
import { moneyFormatter } from './utils';
import { er } from '@fullcalendar/core/internal-common';

export function generateUniqueId() {
    const prefix = 'admin';
    const length = 26;
    const dashPositions = [5, 11, 17, 23]; // Index positions where dashes will be inserted
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = prefix;

    // Generate random characters for the remaining length
    for (let i = prefix.length; i < length; i++) {
        id += characters.charAt(Math.floor(Math.random() * characters.length));
        // Insert dash at the specified positions
        if (dashPositions.includes(i)) {
            id += '-';
        }
    }

    return id;
}

export const updateUserUUID = async () => {
    try {
        const { data } = await axios.post<any>(
            `https://xyr0-v4lw-4eou.m2.xano.io/api:m1RX-Wnu/updateUserUid`,
            {
                user_uid: localStorage.getItem('uid'),
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        );

        // console.log("updated user id", data);
    } catch (err) {
        console.error(err);
    }
};

export const getUser = async (dispatch: any) => {
    const token = localStorage.getItem('token');
    if (!token) {
        // handle case where token is not found
        return;
    }
    try {
        const { data, status } = await axios.get<any>('https://xyr0-v4lw-4eou.m2.xano.io/api:BV_IWA_a:v1/auth/me_new', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch(setUser(data));
        toast.success(`Welcome ${data.first_name} sir!`);
        return data;
    } catch (error) {
        console.error(error);
        // @ts-ignore
        toast.error(error.response.data?.message || `Something Went Wrong!`);
        return null;
        // handle error
    }
};

export const calculateRent = (totalDays: number, baseRent?: number, pdRent?: any, ptRent?: any, nFactor?: any, discount: number = 0, surgeFactor: number = 1): string | undefined => {
    if (!totalDays) {
        return;
    }

    if (baseRent || baseRent === 0) {
        if (pdRent === 0) {
            return ((ptRent - (ptRent * discount) / 100) * surgeFactor)?.toFixed(); // Apply discount to ptRent
        } else {
            const num_days = totalDays;
            const finalRent = baseRent + pdRent * Math.min(num_days, 15) + ptRent + Math.max(num_days - 15, 0) * nFactor;
            const discountedRent = finalRent - (finalRent * discount) / 100; // Apply discount to finalRent
            return (discountedRent * surgeFactor)?.toFixed();
        }
    } else {
        return '';
    }
};

export const calculateProductRent = (product: any, surgeFactor: number = 1): string | undefined => {
    if (!product?.num_days) {
        return;
    }
    const num_days = product?.num_days;
    const finalRent = product?.base_rent + product?.per_day_rent * Math.min(num_days, 15) + product?.rent_per_trip + Math.max(num_days - 15, 0) * product?.normalisation_factor;
    const total = (finalRent * surgeFactor)?.toFixed();
    //@ts-ignore
    return moneyFormatter(total);
};

export const totalProductRent = (fullCart: any): string | undefined => {
    console.log(fullCart);
    if (!fullCart || fullCart?.length === 0) {
        console.log('return');
        return;
    }
    const total = fullCart?.reduce((accumulator: number, product: any) => {
        return Math.round(accumulator + product?.rent * product?.quantity);
    }, 0);

    return moneyFormatter(total);
};

export const totalDeposit = (fullCart: any): string => {
    if (!fullCart || fullCart?.length === 0) {
        return 'No';
    }
    const total = fullCart?.reduce((accumulator: number, product: any) => {
        return Math.round(accumulator + product?.deposit * product?.quantity);
    }, 0);
    return moneyFormatter(total);
};

export const formatAddress = (address: any): string | undefined => {
    console.log('Format Address', address);
    if (!address) return;
    return `${address?.area_details || ''}, ${address?.road || ''}, ${address?.landmark || ''}, ${address?.city || ''}, ${address?.state || ''}, ${address?.pincode || ''}`;
};

// export const getMe = async (navigate, dispatch, setUserLoading) => {
//     setUserLoading(true);
//     try {
//         const user = await getUser(dispatch);
//         console.log('user', user);
//         if (user?.response?.status === 401 || user?.response?.status === 403) {
//             toast.error('Login Again!');
//             navigate('/');
//         } else {
//             if (user?.user_uid) localStorage.setItem('uid', user?.user_uid);
//             toast.success(`Welcome ${user.first_name} sir !`);
//         }
//     } catch (error) {
//         console.log(error);
//         navigate('/');
//         toast.error('Something went wrong!');
//     } finally {
//         setUserLoading(false);
//     }
// };
