import { createBrowserRouter } from 'react-router-dom';
import BlankLayout from '../components/Layouts/BlankLayout';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import { routes } from './routes';
import Error404 from '../pages/Pages/Error404';
import { IsAccessableComponent } from '../components/IsAuthenticated';

const finalRoutes = routes.map((route) => {
    return {
        ...route,
        errorElement: <Error404 />,
        element:
            route.layout === 'blank' ? (
                <BlankLayout>
                    {' '}
                    {/* <Toaster position="top-center" reverseOrder={false} /> */}
                    <IsAccessableComponent path={route.path} type={''}>
                        {route.element}
                    </IsAccessableComponent>
                </BlankLayout>
            ) : (
                <DefaultLayout>
                    <IsAccessableComponent path={route.path} type={''}>
                        {route.element}
                    </IsAccessableComponent>
                </DefaultLayout>
            ),
    };
});

const router = createBrowserRouter(finalRoutes);

// console.log(router);

export default router;
