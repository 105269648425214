import React, { useState } from 'react'

const useSideView = () => {
    const [isSideViewOpen, setIsSideViewOpen] = useState(false);
    const toggleSideView = () => {
        setIsSideViewOpen(prev => !prev)
    }
    return {
        isSideViewOpen,
        toggleSideView,
    }
}




export default useSideView