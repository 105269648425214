import { z } from 'zod';

export const signUpEMailSchema = z
    .object({
        email: z.string().email(),
        password: z.string().min(10, 'Password must be at least 10 characters'),
        confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
        message: 'Passwords must match',
        path: ['confirmPassword'],
    });

export const sendOtpSchema = z.object({
    country_code: z.string().min(1),
    calling_phone_number: z.string().length(10, 'Phone Number must be of 10 digits.'), // Ensure phone number is exactly 10 digits long

});
export const verifyOtpSchema = z.object({
    entered_otp: z.string().min(1),
});



export type TSignUpEmailSchema = z.infer<typeof signUpEMailSchema>;
export type sendOtpSchemaType = z.infer<typeof sendOtpSchema>;
export type verifyOtpSchemaType = z.infer<typeof verifyOtpSchema>;
