import { PropsWithChildren, useState } from 'react';
import { cn } from '../../lib/utils';
import { useEffect, useRef, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '../Icon';
import { Loader } from '@mantine/core';

const PortalContainer = ({ children }) => {
    const portalRoot = useRef(document.createElement('div'));

    useEffect(() => {
        const root = document.getElementById('portal-root');
        root?.appendChild(portalRoot.current);

        return () => {
            root?.removeChild(portalRoot.current);
        };
    }, []);

    return createPortal(children, portalRoot.current);
};

interface SideViewLayoutProps extends PropsWithChildren {
    isOpen: boolean;
    onClose: () => void;
    title: string;
}

const SideViewLayout = ({ isOpen, onClose, title, children }: SideViewLayoutProps) => {
    const [sideViewLoadingEffect, setSideViewLoadingEffect] = useState(false);

    useEffect(() => {
        // Show the loading effect when the component opens
        if (isOpen) {
            setSideViewLoadingEffect(true);

            // Hide the loading effect after 5 seconds
            const timeoutId = setTimeout(() => {
                setSideViewLoadingEffect(false);
            }, 500);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isOpen]);

    return (
        <PortalContainer>
            <Transition appear show={isOpen} as={Fragment}>
                <Transition.Child
                    as={Fragment}
                    enter="transform transition-transform ease-out duration-300"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition-transform ease-in duration-200"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <div className={cn(`fixed top-0 text-black dark:text-white-dark right-0 h-screen sm:w-1/2 w-full bg-white z-50 order-3 ${isOpen ? 'block' : 'hidden'}`)}>
                        <div className="bg-slate-100 dark:bg-slate-800 h-screen  pt-0 relative  overflow-y-auto">
                            <div className="flex justify-between bg-slate-200 dark:bg-slate-900 p-3  items-center  sticky z-10 inset-0">
                                <h2 className="text-xl font-bold text-slate-700 dark:text-slate-400">{title}</h2>
                                <button onClick={onClose} type="button" className="text-white-dark hover:text-dark">
                                    <IconX />
                                </button>
                            </div>
                            <div className="w-full  p-4 ">
                                {sideViewLoadingEffect ? (
                                    <div className="h-[95vh] flex items-center justify-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    children
                                )}
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Transition>
        </PortalContainer>
    );
};

export default SideViewLayout;

// the dialog in headless ui opens the portal as we are doing above
// import { Fragment, PropsWithChildren } from 'react';
// import { cn } from '../../lib/utils';
// import { Dialog, Transition } from '@headlessui/react';

// interface SideViewLayoutProps extends PropsWithChildren {
//     isOpen: boolean;
//     onClose: () => void;
//     title: string;
// }

// const SideViewLayout = ({ isOpen, onClose, title, children }: SideViewLayoutProps) => {
//     return (
//         <Transition appear show={isOpen} as={Fragment}>
//             <Dialog as="div" open={isOpen} onClose={onClose}>
//                 <Transition.Child
//                     as={Fragment}
//                     enter="ease-out duration-300"
//                     enterFrom="opacity-0 scale-95"
//                     enterTo="opacity-100 scale-100"
//                     leave="ease-in duration-200"
//                     leaveFrom="opacity-100 scale-100"
//                     leaveTo="opacity-0 scale-95"
//                 >
//                     <div className={cn(`fixed top-0 right-0 h-full w-1/2 bg-white z-50 order-3 ${isOpen ? 'block' : 'hidden'}`)}>
//                         <div className="bg-gray-800 text-white p-4">
//                             <div className="flex justify-between items-center mb-4">
//                                 <h2 className="text-xl font-bold">{title}</h2>
//                                 <button onClick={onClose} className="text-white">
//                                     Close
//                                 </button>
//                             </div>
//                             <div>{children}</div>
//                         </div>
//                     </div>
//                 </Transition.Child>
//             </Dialog>
//         </Transition>
//     );
// };

// export default SideViewLayout;
