import { useSelector } from 'react-redux';
import { redirect } from 'react-router-dom';
import Error503 from '../pages/Pages/Error503';
import { UserState } from '../store/userSlice';

const AuthenticatedComponent = ({ allowedRoles, children }) => {
    const { user } = useSelector((state: any) => state.user);
    // Check if user is authenticated and has the allowed role
    const isAuthenticated = user && allowedRoles.includes(user.user_role);

    return isAuthenticated ? children : <Error503 />;
};

export const IsAccessableComponent = ({ path, children, type }) => {
    const { user, permissions } = useSelector((state: any) => state?.user)

    if (path == '/auth/login' || path == '/') return children;
    if (user?.role == 'admin') return children;
    console.log(path)
    const isAuthenticated = user && permissions.includes('/' + path?.split('/')[1]);
    if (type == 'sidebar')
        return isAuthenticated ? children : <></>;
    else
        return isAuthenticated ? children : <Error503 />;

}



export default AuthenticatedComponent;
