//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillSkipBackwardCircleFill } from "react-icons/bs";
import { S3FileList } from "../../pages/Apps/S3.js";
import { listObjectsInFolder } from "../../lib/S3.js";

const FolderView = () => {
  const [foldersBojects, setFolderObjects] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { folderName } = useParams();
  const navigate = useNavigate();

  const getFoldersObjects = async (folderName) => {
    try {
      setIsFetching(true);
      const objects = await listObjectsInFolder(
        folderName == "all" ? "" : folderName
      );
      setFolderObjects(objects);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getFoldersObjects(folderName);
  }, [folderName]);

  return (
    <div className="flex flex-col justify-start items-center gap-3 w-full  bg-slate-100 rounded-lg md:p-5 p-3 dark:bg-gray-700 relative min-w-full min-h-screen py-7">
      <button
        onClick={() => navigate('/s3')}
        className="md:text-lg text-sm underline cursor-pointer absolute left-5 top-4 dark:text-gray-300 text-gray-600 flex justify-center items-center md:gap-2 gap-1"
      >
        <BsFillSkipBackwardCircleFill className="md:text-2xl text-xl" />
        Back
      </button>

      <h2 className="md:text-2xl underline text-lg font-medium dark:text-slate-200">
        Folder View
      </h2>

      <S3FileList
        fileList={foldersBojects}
        setFileList={setFolderObjects}
        isFetching={isFetching}
        folderName={folderName == "all" ? "" : folderName}
        getFoldersObjects={getFoldersObjects}
      />
    </div>
  );
};

export default FolderView;
