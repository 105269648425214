import { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '../../App';
import { IRootState } from '../../store';
import { toggleSidebar } from '../../store/themeConfigSlice';
import Footer from './Footer';
import Header from './Header';
import Setting from './Setting';
import Sidebar from './Sidebar';
import Portals from '../../components/Portals';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../lib/busniessFunctions';
import LoginCoverNumber from '../../pages/Authentication/LoginCoverNumber';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import BreadCrumb from '../BreadCrumb';
import { Breadcrumbs } from '@mantine/core';

const DefaultLayout = ({ children }: PropsWithChildren) => {
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();

    const [userLoading, setUserLoading] = useState(false);
    const [showTopButton, setShowTopButton] = useState(false);

    const goToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const onScrollHandler = () => {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            setShowTopButton(true);
        } else {
            setShowTopButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandler);

        // const screenLoader = document.getElementsByClassName('screen_loader');
        // if (screenLoader?.length) {
        //     screenLoader[0].classList.add('animate__fadeOut');
        //     setTimeout(() => {
        //         setShowLoader(false);
        //     }, 200);
        // }

        return () => {
            window.removeEventListener('onscroll', onScrollHandler);
        };
    }, []);

    // user check

    // const [userLoading, setUserLoading] = useState(false);
    // const { user } = useSelector((state) => state.user);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     const getMe = async () => {
    //         setUserLoading(true);
    //         const screenLoader = document.getElementsByClassName('screen_loader');
    //         // setUserLoading(true);

    //         try {
    //             // screenLoader[0].classList.add('animate__fadeOut');

    //             if (screenLoader?.length) {
    //                 screenLoader[0].classList.add('animate__fadeOut');
    //             }
    //             const user = await getUser(dispatch);
    //             console.log('user', user);
    //             if (user?.response?.status === 401 || user?.response?.status === 403) {
    //                 toast.error('Login Again!');
    //                 // navigate('/');
    //                 navigate('/auth/login');
    //             } else if (user) {
    //                 if (user?.user_uid) localStorage.setItem('uid', user?.user_uid);
    //                 toast.success(`Welcome ${user?.first_name} sir !`);
    //                 // navigate('/');
    //             }
    //         } catch (error) {
    //             console.log(error);
    //             navigate('/auth/login');
    //             toast.error('Something went wrong!');
    //         } finally {
    //             // setUserLoading(false);
    //             setUserLoading(false);
    //         }
    //     };

    //     getMe();
    // }, []);

    // useEffect(() => {
    //     if (!user && !userLoading) {
    //         return <LoginCoverNumber />;
    //     }
    //     if (!user && userLoading) {
    //         setShowLoader(false);
    //         return;
    //     }
    // }, [user]);

    // // console.log(user);
    // if (!user && showLoader) {
    //     return <Loader />;
    // }
    // if (!user && !showLoader) {
    //     return <LoginCoverNumber />;
    // }
    // if (!user && showLoader) {
    //     setShowLoader(false);
    //     return;
    // }

    return (
        <App>
            {/* BEGIN MAIN CONTAINER */}
            <div className="relative">
                {/* sidebar menu overlay */}
                <div className={`${(!themeConfig.sidebar && 'hidden') || ''} fixed inset-0 bg-[black]/60 z-50 lg:hidden`} onClick={() => dispatch(toggleSidebar())}></div>
                {/* screen loader */}
                {/* {userLoading && <Loader />} */}
                <div className="fixed bottom-6 ltr:right-6 rtl:left-6 z-50">
                    {showTopButton && (
                        <button type="button" className="btn btn-outline-primary rounded-full p-2 animate-pulse bg-[#fafafa] dark:bg-[#060818] dark:hover:bg-primary" onClick={goToTop}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7l4-4m0 0l4 4m-4-4v18" />
                            </svg>
                        </button>
                    )}
                </div>

                <div id="portal-root"></div>

                {/* BEGIN APP SETTING LAUNCHER */}
                <Setting />
                {/* END APP SETTING LAUNCHER */}

                <div className={`${themeConfig.navbar} main-container text-black dark:text-white-dark min-h-screen`}>
                    {/* BEGIN SIDEBAR */}
                    <Sidebar />
                    {/* END SIDEBAR */}

                    <div className="main-content flex flex-col min-h-screen">
                        {/* BEGIN TOP NAVBAR */}
                        <Header />
                        {/* END TOP NAVBAR */}

                        {/* BEGIN CONTENT AREA */}
                        <Suspense>
                            <div className={`${themeConfig.animation} md:p-6 p-2 py-6 animate__animated`}>
                                <BreadCrumb />
                                {children}
                            </div>
                        </Suspense>
                        {/* END CONTENT AREA */}

                        {/* BEGIN FOOTER */}
                        <Footer />
                        {/* END FOOTER */}
                        <Portals />
                    </div>
                </div>
            </div>
        </App>
    );
};

export default DefaultLayout;
