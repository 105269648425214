import { S3Client, PutObjectCommand, ListObjectsV2Command, GetObjectCommand, DeleteObjectCommand, ListObjectsCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import toast from 'react-hot-toast';

export const BUCKET_NAME = 'sharepal-images';
const AWS_SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY as string;
const AWS_ACCESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY_ID as string;
const region = 'ap-south-1';
// export const CLOUDFRONT_URL = 'https://dzo5ib7e70yg4.cloudfront.net';
export const CLOUDFRONT_URL = import.meta.env.VITE_CLOUDFRONT_URL as string;

console.log(AWS_SECRET_ACCESS_KEY, AWS_ACCESS_KEY_ID);

const s3 = new S3Client({
    region,
    credentials: {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
    },
});

const generatePresignedUrl = async (fileName: string, fileType: string, action: 'upload' | 'download'): Promise<string> => {
    const params = {
        Bucket: BUCKET_NAME,
        Key: fileName,
        ContentType: fileType,
    };

    let command;
    if (action === 'upload') {
        command = new PutObjectCommand(params);
    } else if (action === 'download') {
        command = new GetObjectCommand(params);
    } else {
        throw new Error('Invalid action specified');
    }

    const signedUrl = await getSignedUrl(s3, command, { expiresIn: 3600 });
    return signedUrl;
};

export const uploadFiles = async (
    files: { file: File }[],
    folderName: string | null,
    setLoading: (loading: boolean) => void,
    // showNotification: (type: string, message: string, duration: number, position: string, description: string) => void,
    setSelectedFiles: (files: []) => void,
    setFileList: (list: any[]) => void
): Promise<string[]> => {
    try {
        setLoading(true);
        const uploadedFiles: any[] = [];

        for (const file of files) {
            let key = file.file.name;
            if (folderName) {
                key = `${folderName}/${Date.now().toString()}-${file.file.name}`;
            }

            const presignedUrl = await generatePresignedUrl(key, file.file.type, 'upload');

            try {
                await fetch(presignedUrl, {
                    method: 'PUT',
                    body: file.file,
                });

                uploadedFiles.push({
                    Location: presignedUrl,
                    Key: key,
                });
            } catch (error) {
                console.error(`Error uploading file ${file.file.name}:`, error);
            }
        }

        objectInFolderCache.delete(folderName || '');

        toast.success('Files Uploaded Successfully!');

        setSelectedFiles([]);
        //@ts-ignore
        setFileList((prev: any) => [...uploadedFiles, ...prev]);

        return uploadedFiles.map((file) => file.Location);
    } catch (error) {
        toast.error('Something went wrong!');
        throw error;
    } finally {
        setLoading(false);
    }
};

export const uploadFolder = async (folderHandle: any, folderName: string, setLoading: (loading: boolean) => void) => {
    setLoading(true);

    const traverseFolder = async (folder: any) => {
        const entries = folderHandle.values();
        const folderPath = folder?.kind === 'directory' ? `${folder?.name}/` : '';

        for await (const entry of entries) {
            if (entry.kind === 'file') {
                const file = await entry.getFile();
                const key = folderPath + entry.name;

                const presignedUrl = await generatePresignedUrl(key, 'application/octet-stream', 'upload');

                try {
                    await fetch(presignedUrl, {
                        method: 'PUT',
                        body: file,
                    });

                    toast.success(`File ${entry.name} uploaded successfully to ${key}`);
                } catch (error: any) {
                    toast.error(`Error uploading file ${entry.name}: ${error.message}`);
                }
            } else if (entry.kind === 'directory') {
                await traverseFolder(entry);
            }
        }
    };

    try {
        await traverseFolder(folderHandle);
        toast.success('Folder Uploaded Successfully!');
    } catch (error: any) {
        toast.error(`Error uploading folder: ${error.message}`);
    } finally {
        setLoading(false);
    }
};

// export const deleteFile = async (fileName: string, showNotification?: (type: string, message: string, duration: number, position: string, description: string) => void): Promise<void> => {
export const deleteFile = async (fileName: string): Promise<void> => {
    try {
        const params = {
            Bucket: BUCKET_NAME,
            Key: fileName,
        };

        await s3.send(new DeleteObjectCommand(params));
        toast.success('File Delted Successfully!');
    } catch (error) {
        toast.error('Something went wrong!');
        throw error;
    }
};

const listObjectsCache = new Map<string, any[]>();

export const listObjects = async (): Promise<any[]> => {
    if (listObjectsCache.has(BUCKET_NAME)) {
        return listObjectsCache.get(BUCKET_NAME)!;
    }

    const params = {
        Bucket: BUCKET_NAME,
    };

    const data = await s3.send(new ListObjectsV2Command(params));
    const sortedObjects = data.Contents?.sort((a, b) => new Date(b.LastModified!).getTime() - new Date(a.LastModified!).getTime());

    listObjectsCache.set(BUCKET_NAME, sortedObjects || []);
    return sortedObjects || [];
};

export const createFolder = async (folderName: string, folderExistsCallback?: () => void): Promise<string> => {
    try {
        const existingObjects = await listObjectsInFolder(folderName);
        if (existingObjects.length > 0 && folderExistsCallback) {
            folderExistsCallback();
            return `Folder '${folderName}' already exists`;
        }

        const params = {
            Bucket: BUCKET_NAME,
            Key: `${folderName}/`,
            Body: '',
        };

        await s3.send(new PutObjectCommand(params));
        return `Folder '${folderName}' created successfully`;
    } catch (error) {
        throw error;
    }
};

const listFoldersCache = new Map<number, string[]>();

export const listFolders = async (pageSize: number = 1000, update: boolean = false): Promise<string[]> => {
    if (listFoldersCache.has(pageSize) && !update) {
        return listFoldersCache.get(pageSize)!;
    }

    let folderNames: string[] = [];
    let continuationToken: string | null = null;

    do {
        const params = {
            Bucket: BUCKET_NAME,
            Delimiter: '/',
            MaxKeys: pageSize,
            ContinuationToken: continuationToken || undefined,
        };

        const data = await s3.send(new ListObjectsV2Command(params));

        folderNames = [...folderNames, ...(data?.CommonPrefixes?.map((prefix) => prefix.Prefix.slice(0, -1)) || [])];

        continuationToken = data.NextContinuationToken || null;
        await new Promise((resolve) => setTimeout(resolve, 100));
    } while (continuationToken);

    listFoldersCache.set(pageSize, folderNames);
    return folderNames;
};

const objectInFolderCache = new Map<string, any[]>();

export const listObjectsInFolder = async (folderName: string): Promise<any[]> => {
    if (objectInFolderCache.has(folderName)) {
        return objectInFolderCache.get(folderName)!;
    }

    const params = {
        Bucket: BUCKET_NAME,
        Prefix: folderName,
    };

    const data = await s3.send(new ListObjectsV2Command(params));
    const objects = data.Contents || [];

    const filesInFolder = objects.filter((object) => !object.Key?.endsWith('/'));
    const sortedObjects = filesInFolder.sort((a, b) => new Date(b.LastModified!).getTime() - new Date(a.LastModified!).getTime());

    objectInFolderCache.set(folderName, sortedObjects);
    return sortedObjects;
};

export const deleteFolder = async (folderName: string): Promise<void> => {
    try {
        const params = {
            Bucket: BUCKET_NAME,
            Prefix: folderName,
        };

        const data = await s3.send(new ListObjectsV2Command(params));
        const objects = data.Contents || [];

        await Promise.all(
            objects.map(async (object) => {
                const deleteParams = {
                    Bucket: BUCKET_NAME,
                    Key: object.Key,
                };
                await s3.send(new DeleteObjectCommand(deleteParams));
            })
        );
    } catch (error) {
        throw error;
    }
};



