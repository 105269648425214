import React, { useState } from 'react'

const useFormData = (initialFormData) => {
    const [formData, setFormData] = useState(initialFormData);
    const resetFormData = () => {
        setFormData(initialFormData);
    }
    const handleFormDataChange = (key: string, value: any) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }
    return {
        formData,
        resetFormData,
        handleFormDataChange,
    }
}

export default useFormData