import {
    S3Client,
    ListObjectsV2Command,
    GetObjectCommand,
    PutObjectCommand,
    DeleteObjectCommand,
    CreateMultipartUploadCommand,
    UploadPartCommand,
    CompleteMultipartUploadCommand,
    CopyObjectCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import toast from 'react-hot-toast';
import fs from 'fs'; // Add fs for file handling in Node.js


// Configuration - Replace with your actual values
const REGION = 'your-region';
const IDENTITY_POOL_ID = 'your-identity-pool-id';

// Uncomment if using Cognito for authentication
// const s3Client = new S3Client({
//   region: REGION,
//   credentials: fromCognitoIdentityPool({
//     client: new CognitoIdentityClient({ region: REGION }),
//     identityPoolId: IDENTITY_POOL_ID,
//   }),
// });

export const BUCKET_NAME = 'sharepal-images';
const AWS_SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY as string;
const AWS_ACCESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY_ID as string;
const region = 'ap-south-1';
// export const CLOUDFRONT_URL = 'https://dzo5ib7e70yg4.cloudfront.net';
export const CLOUDFRONT_URL = import.meta.env.VITE_CLOUDFRONT_URL as string;

console.log(AWS_SECRET_ACCESS_KEY, AWS_ACCESS_KEY_ID);

const s3Client = new S3Client({
    region,
    credentials: {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
    },
});

// Cache to store previously fetched objects
const cache = new Map<string, any>();

// Function to list objects and folders in an S3 bucket
export const listS3Objects = async (bucketName: string, prefix: string = '') => {
    // Check if we have cached data for the requested prefix
    if (cache.has(prefix)) {
        console.log(`Fetching data from cache for prefix: ${prefix}`);
        return cache.get(prefix);
    }

    try {
        const data = await s3Client.send(
            new ListObjectsV2Command({
                Bucket: bucketName,
                Prefix: prefix,
                Delimiter: '/', // This ensures we get folders as well as files
            })
        );

        console.log('S3 Data:', data);

        // Store the fetched data in cache
        cache.set(prefix, data);

        return data;
    } catch (err) {
        console.error('Error listing objects', err);
        return null;
    }
};

export const getFileUrl = async (fileName: string, fileType: string, action: 'upload' | 'download'): Promise<string> => {
    const params = {
        Bucket: BUCKET_NAME,
        Key: fileName,
        // ContentType: fileType, // Uncomment if you want to specify content type for uploads
    };

    let command;
    if (action === 'upload') {
        command = new PutObjectCommand(params);
    } else if (action === 'download') {
        command = new GetObjectCommand(params);
    } else {
        throw new Error('Invalid action specified');
    }

    const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return signedUrl;
};

// Upload a file to S3
export const uploadFileToS3 = async (file: File, prefix: string = '') => {
    const key = `${prefix}${file.name}`; // File key with folder prefix

    try {
        const command = new PutObjectCommand({
            Bucket: BUCKET_NAME,
            Key: key,
            Body: file,
            ContentType: file.type,
        });

        const result = await s3Client.send(command);
        console.log('File uploaded successfully:', result);
        return result;
    } catch (err) {
        console.error('Error uploading file', err);
        return null;
    }
};

// Function to upload multiple files to S3
export const uploadFiles = async (files: File[], prefix: string = '') => {
    const uploadResults: any = [];

    try {
        // Loop through each file and upload it
        for (const file of files) {
            const key = `${prefix}${file.name}`; // File key with folder prefix

            // Create a command for uploading each file
            const command = new PutObjectCommand({
                Bucket: BUCKET_NAME,
                Key: key,
                Body: file,
                ContentType: file.type,
            });

            // Send the upload command
            const result = await s3Client.send(command);
            console.log(`File ${file.name} uploaded successfully:`, result);

            // Collect the result for each file

            uploadResults.push({ fileName: file.name, result });
        }

        return uploadResults; // Return the array of results
    } catch (err) {
        console.error('Error uploading files', err);
        return null;
    }
};

// Create a new folder in S3
export const createFolderInS3 = async (folderName: string, prefix: string = '') => {
    const folderKey = `${prefix}${folderName}/`; // Folder key must end with "/"

    try {
        const command = new PutObjectCommand({
            Bucket: BUCKET_NAME,
            Key: folderKey,
            Body: '', // Empty body to create a folder
        });

        const result = await s3Client.send(command);
        console.log('Folder created successfully:', result);
        return result;
    } catch (err) {
        console.error('Error creating folder', err);
        return null;
    }
};

// Delete an object from S3 (file or folder)
export const deleteObjectFromS3 = async (key: string) => {
    try {
        const command = new DeleteObjectCommand({
            Bucket: BUCKET_NAME,
            Key: key,
        });

        const result = await s3Client.send(command);
        console.log('Object deleted successfully:', result);
        return result;
    } catch (err) {
        console.error('Error deleting object', err);
        return null;
    }
};

// Multipart upload utility (for large files)
export const multipartUploadToS3 = async (file: File, prefix: string = '', partSize = 5 * 1024 * 1024) => {
    const key = `${prefix}${file.name}`;

    try {
        const createUploadCommand = new CreateMultipartUploadCommand({
            Bucket: BUCKET_NAME,
            Key: key,
            ContentType: file.type,
        });

        const multipartUpload = await s3Client.send(createUploadCommand);
        const uploadId = multipartUpload.UploadId;

        const parts = [];
        let partNumber = 1;
        for (let start = 0; start < file.size; start += partSize, partNumber++) {
            const partEnd = Math.min(start + partSize, file.size);
            const blob = file.slice(start, partEnd);

            const uploadPartCommand = new UploadPartCommand({
                Bucket: BUCKET_NAME,
                Key: key,
                PartNumber: partNumber,
                UploadId: uploadId,
                Body: blob,
            });

            const uploadPartResult = await s3Client.send(uploadPartCommand);
            //@ts-ignore
            parts.push({
                ETag: uploadPartResult.ETag,
                PartNumber: partNumber,
            });
        }

        const completeCommand = new CompleteMultipartUploadCommand({
            Bucket: BUCKET_NAME,
            Key: key,
            UploadId: uploadId,
            MultipartUpload: {
                Parts: parts,
            },
        });

        const completeResult = await s3Client.send(completeCommand);
        console.log('Multipart upload completed successfully:', completeResult);
        return completeResult;
    } catch (err) {
        console.error('Error in multipart upload', err);
        return null;
    }
};



export const renameObjectInS3 = async (oldKey: string, newKey: string) => {
    try {
        // Step 1: Copy the object to the new key (new name)
        const copyCommand = new CopyObjectCommand({
            Bucket: BUCKET_NAME,
            CopySource: `${BUCKET_NAME}/${oldKey}`, // Specify the old key (source) as BUCKET/oldKey
            Key: newKey, // Specify the new key (new name)
        });

        await s3Client.send(copyCommand);
        console.log(`Object copied successfully from ${oldKey} to ${newKey}`);

        // Step 2: Delete the old object
        await deleteObjectFromS3(oldKey);
        console.log(`Old object ${oldKey} deleted successfully`);

        return { success: true };
    } catch (err) {
        console.error('Error renaming object', err);
        return { success: false, error: err };
    }
};


// Function to download a file from S3
// export const downloadFileFromS3 = async (fileName: string, downloadPath: string): Promise<void> => {
//     try {
//         const command = new GetObjectCommand({
//             Bucket: BUCKET_NAME,
//             Key: fileName,
//         });

//         const response = await s3Client.send(command);
        
//         // Stream the response to a file
//         const writeStream = fs.createWriteStream(downloadPath);
//         response.Body.pipe(writeStream);

//         return new Promise((resolve, reject) => {
//             writeStream.on('finish', () => {
//                 console.log(`File downloaded successfully to ${downloadPath}`);
//                 resolve();
//             });
//             writeStream.on('error', (err) => {
//                 console.error('Error downloading file', err);
//                 reject(err);
//             });
//         });
//     } catch (err) {
//         console.error('Error downloading file', err);
//         throw new Error('Download failed');
//     }
// };