//@ts-nocheck

import React, { useEffect, useState } from 'react';
import { BsFillFolderSymlinkFill, BsThreeDotsVertical } from 'react-icons/bs';
import { Link } from 'react-router-dom';
// import ConfirmationModal from '../../components/ConfirmModal/ConfirmationModal.jsx';
import { AiOutlineDelete } from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import { createFolder, listFolders, uploadFolder } from '../../lib/S3';
import ConfirmationModal from '../ConfirmationModal';

// Define the props for ShareItem
interface ShareItemProps {
    url: string;
    fileKey: string;
    handleDelete: (fileKey: string) => Promise<void>;
}

export const ShareItem: React.FC<ShareItemProps> = ({ url, fileKey, handleDelete }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
    };

    const handleFileDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsDeleteModalOpen(true);
    };

    const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Create a link for downloading the file
        const link = document.createElement('a');
        link.href = url;
        link.download = 'downloaded-file';
        link.click();
    };

    const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // Copy the URL to the clipboard
        navigator.clipboard.writeText(url).then(() => {
            toast.success('Copied Successfully!');
        });
    };

    const handleConfirmDelete = async () => {
        try {
            await handleDelete(fileKey);
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <ConfirmationModal isOpen={isDeleteModalOpen} onConfirm={handleConfirmDelete} onClose={handleCancelDelete} message={`Are you sure you want to delete this File ${fileKey}? `} />
            <div data-dial-init="" className="absolute bottom-0 right-0 group">
                <div id="speed-dial-menu-text-inside-button" className="hidden flex-col items-center group-hover:flex mb-4 space-y-2">
                    <button
                        onClick={handleFileDelete}
                        type="button"
                        className="w-12 h-12 text-red-500 bg-white rounded-full border border-red-200 dark:border-red-600 hover:text-red-900 shadow-sm dark:hover:text-white dark:text-red-100 hover:bg-red-50 dark:bg-red-700 dark:hover:bg-red-600 focus:ring-4 focus:ring-red-300 focus:outline-none dark:focus:ring-red-400 z-20"
                    >
                        <AiOutlineDelete className="w-4 h-4 mx-auto mb-1" />
                        <span className="block mb-px text-xs font-medium">Del</span>
                    </button>

                    <button
                        onClick={handleDownload}
                        type="button"
                        className="w-[56px] h-[56px] text-slate-500 bg-white rounded-full border border-slate-200 dark:border-slate-600 hover:text-slate-900 shadow-sm dark:hover:text-white dark:text-slate-400 hover:bg-slate-50 dark:bg-slate-700 dark:hover:bg-slate-600 focus:ring-4 focus:ring-slate-300 focus:outline-none dark:focus:ring-slate-400 z-20"
                    >
                        <svg className="w-4 h-4 mx-auto mb-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                            <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="block mb-px text-xs font-medium">Save</span>
                    </button>

                    <button
                        onClick={handleCopy}
                        type="button"
                        className="w-[56px] h-[56px] text-slate-500 bg-white rounded-full border border-slate-200 dark:border-slate-600 hover:text-slate-900 shadow-sm dark:hover:text-white dark:text-slate-400 hover:bg-slate-50 dark:bg-slate-700 dark:hover:bg-slate-600 focus:ring-4 focus:ring-slate-300 focus:outline-none dark:focus:ring-slate-400 z-20"
                    >
                        <svg className="w-4 h-4 mx-auto mb-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M5 9V4.13a2.96 2.96 0 0 0-1.293.749L.879 7.707A2.96 2.96 0 0 0 .13 9H5Zm11.066-9H9.829a2.98 2.98 0 0 0-2.122.879L7 1.584A.987.987 0 0 0 6.766 2h4.3A3.972 3.972 0 0 1 15 6v10h1.066A1.97 1.97 0 0 0 18 14V2a1.97 1.97 0 0 0-1.934-2Z" />
                            <path d="M11.066 4H7v5a2 2 0 0 1-2 2H0v7a1.969 1.969 0 0 0 1.933 2h9.133A1.97 1.97 0 0 0 13 18V6a1.97 1.97 0 0 0-1.934-2Z" />
                        </svg>
                        <span className="block mb-px text-xs font-medium">Copy</span>
                    </button>
                </div>
                <button
                    type="button"
                    data-dial-toggle="speed-dial-menu-text-inside-button"
                    aria-controls="speed-dial-menu-text-inside-button"
                    aria-expanded="false"
                    className="flex items-center justify-center text-white bg-slate-700 rounded-full w-14 h-14 hover:bg-slate-800 dark:bg-slate-600 dark:hover:bg-slate-700 focus:ring-4 focus:ring-slate-300 focus:outline-none dark:focus:ring-slate-800"
                >
                    <svg className="w-5 h-5 transition-transform group-hover:rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 1v16M1 9h16" />
                    </svg>
                </button>
            </div>
        </>
    );
};

// Define the props for OrderItem
interface OrderItemProps {
    url: string;
}

export const OrderItem: React.FC<OrderItemProps> = ({ url }) => {
    return (
        <div className="w-full ">
            <div className="w-full h-56 bg-white border border-slate-200 rounded-lg shadow dark:bg-slate-800 dark:border-slate-700 overflow-hidden ">
                <img loading="lazy" className="rounded-t-lg w-full h-full object-contain hover:scale-110 transition-all" src={url} alt="product image" />
            </div>
        </div>
    );
};

interface FolderManagementProps { }

export const FolderManagement: React.FC<FolderManagementProps> = () => {
    const [folderName, setFolderName] = useState<string>('');
    const [folders, setFolders] = useState<string[]>([]);
    const [isCreating, setIsCreating] = useState<boolean>(false);

    useEffect(() => {
        loadFolders();
    }, []); // Load folders when the component mounts

    const loadFolders = async (update?: boolean) => {
        console.log('Loading folders...');
        try {
            const folderList = await listFolders(1000, update);
            setFolders(folderList);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCreateFolder = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setIsCreating(true);
            if (folderName.trim() === '') {
                console.error('Folder name cannot be empty.');
                return;
            }

            const message = await createFolder(folderName.trim());
            alert(message);
            if (message) {
                setFolderName('');
                loadFolders(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsCreating(false);
        }
    };

    const handleConfirmDelete = async (folderName: string) => {
        try {
            alert('Feature Disabled');
            return;
            // await deleteFolder(folderName);
            // loadFolders();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="flex flex-col justify-center items-center gap-3 w-full mt-5 bg-slate-100 rounded-lg md:p-5 p-3 dark:bg-slate-800 relative">
                <h2 className="md:text-xl underline text-sm font-medium dark:text-slate-200">Folder Management</h2>
                <form onSubmit={handleCreateFolder} className="w-full flex items-center rounded-md justify-center gap-2 sticky md:top-0 top-14 md:p-4 p-2 bg-slate-600 sm:flex-row z-10">
                    <input
                        type="text"
                        placeholder="Enter folder name"
                        value={folderName}
                        className=" flex-[1_0_150px] md:flex-[1_0_250px] p-2.5 rounded-md" // Replace with your actual input class name
                        onChange={(e) => setFolderName(e.target.value)}
                    />
                    {!isCreating ? (
                        <button className="btn btn-primary min-w-max" type="submit">
                            Create Folder
                        </button>
                    ) : (
                        <button type="button"> Creating... </button>
                    )}
                </form>

                <ul className="py-5 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:gap-8 gap-5">
                    <Link to={`s3/all`} className="px-4 py-3 bg-slate-300 rounded-lg dark:bg-slate-600 dark:text-slate-100">
                        <li className="flex items-center justify-between gap-3 capitalize">
                            <div className="flex items-center justify-center gap-3 cursor-pointer">
                                <BsFillFolderSymlinkFill className="text-2xl" />
                                <span> All </span>
                            </div>
                        </li>
                    </Link>
                    {folders?.map((folder) => (
                        <Link key={folder} to={`/s3/${folder}`} className="px-4 py-3 relative bg-slate-300 rounded-lg dark:bg-slate-600 dark:text-slate-100">
                            <li className="flex items-center justify-between gap-3 capitalize">
                                <div className="flex items-center justify-center gap-3 cursor-pointer">
                                    <BsFillFolderSymlinkFill className="text-2xl" />
                                    <span className="max-w-[100px] h-5 overflow-hidden">{folder}</span>
                                </div>
                                <HoverOptions handleConfirmDelete={() => handleConfirmDelete(folder)} />
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </>
    );
};

interface HoverOptionsProps {
    handleConfirmDelete: () => void;
}

const HoverOptions: React.FC<HoverOptionsProps> = ({ handleConfirmDelete }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsDeleteModalOpen(true);
    };

    return (
        <>
            <ConfirmationModal isOpen={isDeleteModalOpen} onConfirm={handleConfirmDelete} onClose={handleCancelDelete} message="Are you sure you want to delete this Folder?" />
            <div data-dial-init="" className="absolute bottom-0 right-0 group">
                <div id="speed-dial-menu-text-inside-button" className="hidden flex-col items-center group-hover:flex mb-4 space-y-2">
                    <button
                        onClick={handleDelete}
                        type="button"
                        className="w-12 h-12 text-red-500 bg-white rounded-full border border-red-200 dark:border-red-600 hover:text-red-900 shadow-sm dark:hover:text-white dark:text-red-100 hover:bg-red-50 dark:bg-red-700 dark:hover:bg-red-600 focus:ring-4 focus:ring-red-300 focus:outline-none dark:focus:ring-red-400 z-20"
                    >
                        <AiOutlineDelete className="w-4 h-4 mx-auto mb-1" />
                        <span className="block mb-px text-xs font-medium">Del</span>
                    </button>
                </div>
                <button
                    type="button"
                    onClick={(e) => e.preventDefault()}
                    data-dial-toggle="speed-dial-menu-text-inside-button"
                    aria-controls="speed-dial-menu-text-inside-button"
                    aria-expanded="false"
                    className="flex items-center justify-center text-slate-800 dark:text-slate-200 bg-slate-300 rounded-full w-12 h-12 hover:bg-slate-400 dark:bg-slate-600 dark:hover:bg-slate-700 focus:ring-4 focus:ring-slate-300 focus:outline-none dark:focus:ring-slate-800"
                >
                    <BsThreeDotsVertical className="cursor-help" />
                </button>
            </div>
        </>
    );
};

export const S3FolderUpload: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [selectedFolder, setSelectedFolder] = useState<FileSystemDirectoryHandle | null>(null);


    const handleSelectFolder = async () => {
        try {
            // Check if the showDirectoryPicker API is available
            if ('showDirectoryPicker' in window) {
                const folderHandle = await (window as any).showDirectoryPicker();
                setSelectedFolder(folderHandle);
            } else {
                throw new Error('showDirectoryPicker is not supported in this browser');
            }
        } catch (error) {
            console.error('Error selecting folder:', error);
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const items = event.dataTransfer.items;

        if (items.length === 1 && items[0].kind === 'directory') {
            const folderHandle = await items[0]?.getAsFileSystemHandle();
            setSelectedFolder(folderHandle as FileSystemDirectoryHandle);
            console.log(folderHandle);
        } else {
            // Handle invalid drop here (e.g., show an error message)
        }
    };

    const handleUpload = async () => {
        if (selectedFolder) {
            setIsLoading(true);

            try {
                await uploadFolder(selectedFolder, '', setIsLoading);
            } catch (error) {
                console.error('Error uploading folder:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div
            className="flex items-center justify-center gap-1 flex-col bg-slate-400 dark:bg-slate-800 rounded-md"
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
        >
            <h2 className="editHeading">Upload Folder</h2>
            <button onClick={handleSelectFolder} className="flex items-center justify-center w-full p-5 bg-slate-300 dark:bg-slate-900 rounded-md">
                <label
                    className={`flex flex-col items-center justify-center w-full h-max border-2 border-slate-300 border-dashed rounded-lg cursor-pointer bg-slate-50 dark:bg-slate-900 dark:hover:bg-slate-950 hover:bg-slate-100 dark:border-slate-600 dark:hover:border-slate-500 p-2 ${isDragging ? 'dark:border-blue-500 border-blue-300' : 'border-slate-300'
                        }`}
                >
                    <div className="flex flex-col items-center justify-center">
                        <svg className="w-8 h-8 mb-4 text-slate-500 dark:text-slate-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                        </svg>
                        <p className="mb-2 text-sm text-slate-500 dark:text-slate-400">
                            <span className="font-semibold">Click or Drag to upload Folder</span>
                        </p>
                    </div>
                </label>
            </button>

            {selectedFolder && (
                <div>
                    <h3 className="text-xl font-semibold">Selected Folder:</h3>
                    <p className="mt-2">Name: {selectedFolder.name}</p>
                    <p>Kind: {selectedFolder.kind}</p>
                    <button
                        className="borderBtn1" // Replace with your actual button class
                        onClick={handleUpload}
                        disabled={isLoading}
                    >
                        Upload Folder
                    </button>
                </div>
            )}

            {isLoading && <p>Uploading...</p>}
        </div>
    );
};
