import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowBackward, IconArrowForward } from './Icon';
// import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';

const BackButton = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };
    const goNext = () => {
        navigate(1);
    };

    return (
        <li className="flex flex-row gap-4 ">
            <button type="button" className=" " onClick={goBack}>
                <IconArrowBackward />
            </button>
            <button type="button" className="" onClick={goNext}>
                <IconArrowForward />
            </button>
        </li>
    );
};

export default BackButton;
